.modal_container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  background: #fff;
  transition: all 0.2s;
  overflow: scroll;
}

.backdrop_open {
  visibility: visible;
  opacity: 1;
  z-index: 999999;
}

.backdrop_close {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.modal {
  background: #fff;
  padding: 2rem;
  width: 60rem;
  position: relative;
  top: 5rem;
  right: 0;
  left: 0;
  z-index: 9;
  margin: 0 auto;
  border-radius: 5px;
  /* box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08),
    0 31px 41px 0 rgba(33, 43, 54, 0.2); */
  transition: all 0.8s;

  border: 1px solid rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 690px) {
  .modal {
    width: 100%;
    top: 8rem;
  }
}

.modal .close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-family: Sailec;
  font-weight: firma-bold;
  cursor: pointer;
}

.open {
  opacity: 1;
  transform: translateY(1rem);
  z-index: 99999999999;
}

.close {
  transform: translateY(-1rem);
  opacity: 0;
}

.banner {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

@keyframes moveUp {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(150px);
  }
}
